<template>
  <div>
      <Divider dashed ><span class="divider-text">资源检索</span></Divider>

      <Row class="p-b-5">
          <label>媒体场景：</label>
          <i-col span="24">
              <div v-for="category in allowCategorys" :key="category.id" class="p-l-20 p-r-20 m-b-2"
                @click="handleChangeCategory(category.id)" :class="categoryId === category.id?'workplatform-radio-button-active':'workplatform-radio-button'">
                {{category.name}}
              </div>
          </i-col>
      </Row>

      <component v-if="categoryId>0" :is="currentComponent"></component>
  </div>
</template>

<script>
import { getCategoryLists } from '@/api/product/category'

export default {
  components: {
    defalutComponent: () => import('../leftComponents/Default'),
    parkComponent: () => import('../leftComponents/Park'),
    otherComponent: () => import('../leftComponents/Other')
  },
  data () {
    return ({
      currentComponent: '',
      allowCategorys: []
    })
  },
  created () {
    this.initPageData()
  },
  methods: {
    initPageData () {
      getCategoryLists().then(res => {
        // 过滤掉 套装 不能检索库存
        this.allowCategorys = res.filter(x => x.id !== 7)
        // this.allowCategorys = res.filter(x => [1, 15, 16].includes(x.id))
        if (this.$store.state.stock) {
          this.handleChangeCategory(this.allowCategorys[0].id)
        }
      })
    },
    handleChangeCategory (categoryId) {
      // if (this.categoryId === categoryId) { return }
      // 开始切换地图
      this.$store.commit('set_stock_categoryId', categoryId)
      // 切换查询组件
      if ([1, 15, 16].includes(categoryId)) {
        this.currentComponent = 'defalutComponent'
        this.$emit('on-change', true)
      } else if (categoryId === 21) {
        this.currentComponent = 'parkComponent'
        this.$emit('on-change', true)
      } else {
        this.currentComponent = 'otherComponent'
        this.$emit('on-change', false)
      }
    }
  },
  computed: {
    categoryId () {
      return this.$store.state.stock.categoryId
    }
  }
}
</script>
